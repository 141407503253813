import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './ServiceRoll.module.scss';

const ServiceRoll = ({ data }) => {
  const { edges: services } = data.allMarkdownRemark;

  return (
    <div className="columns is-multiline" style={{ marginBottom: 100 }}>
      {services &&
        services.map(({ node: service }) => (
          <div className="is-parent column is-12-mobile is-6" key={service.id}>
            <article className={styles.service}>
              {service.frontmatter.svg != null ? (
                <img
                  className={styles.image}
                  alt=""
                  style={{
                    backgroundImage: `url(${service.frontmatter.svg.publicURL})`,
                  }}
                />
              ) : (
                <div className={styles.image} />
              )}
              <div className={styles.text}>
                <div
                  className={styles.title}
                  to={service.fields.slug}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: service.frontmatter.title,
                  }}
                />
                <div className={styles.line} />
                <div className={styles.description}>
                  {service.frontmatter.short_description}
                </div>
              </div>
            </article>
          </div>
        ))}
    </div>
  );
};

ServiceRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query ServiceRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: { fields: { collection: { eq: "services" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                collection
              }
              frontmatter {
                svg {
                  publicURL
                }
                title
                short_description
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ServiceRoll data={data} count={count} />}
  />
);
