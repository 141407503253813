import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import quoteIcon from '../img/icons/quote.svg';
import { HTMLContent } from './Content';
import * as styles from './TestimonialRoll.module.scss';

class TestimonialRoll extends React.PureComponent {
  render() {
    const { data } = this.props;

    if (data.allMarkdownRemark) {
      const { edges: testimonials } = data.allMarkdownRemark;

      const settings = {
        dots: true,
        arrows: false,
        swipeToSlide: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 6000,
        draggable: true,
        slidesToShow: 1,
      };

      const testimonialSlides = testimonials.map(({ node: t }) => (
        <div
          key={`testimonial-slide-${t.id}`}
          className={styles.testimonialSlide}
        >
          <div className={styles.testimonialCard}>
            <img src={quoteIcon} className={styles.quoteIcon} alt="" />
            <div className={styles.testimonialContent}>
              <HTMLContent content={t.html} />
            </div>
            <div className={styles.testimonialRight}>
              <div className={styles.testimonialAuthor}>
                — {t.frontmatter.name}
              </div>
              {t.frontmatter.testimonial_url ? (
                <span>
                  <div className={styles.viewLinkSeperator}></div>
                  <a
                    className={styles.viewLink}
                    href={t.frontmatter.testimonial_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>View full testimonial →</div>
                  </a>
                </span>
              ) : null}
            </div>
          </div>
        </div>
      ));

      return (
        <div className={styles.sliderContainer}>
          <Slider {...settings}>{testimonialSlides}</Slider>
        </div>
      );
    }
    return null;
  }
}

TestimonialRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { fields: { collection: { eq: "testimonials" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
                collection
              }
              html
              frontmatter {
                name
                testimonial_url
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TestimonialRoll data={data} count={count} />}
  />
);
