import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import * as styles from './ClientRoll.module.scss';

class ClientRoll extends React.PureComponent {
  render() {
    const { data } = this.props;

    if (data.allMarkdownRemark) {
      const { edges: clients } = data.allMarkdownRemark;

      const settings = {
        dots: false,
        arrows: false,
        swipeToSlide: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        draggable: true,
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              swipeToSlide: false,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              swipeToSlide: false,
              slidesToScroll: 2,
            },
          },
          // {
          //   breakpoint: 480,
          //   settings: {
          //     slidesToShow: 1,
          //     slidesToScroll: 1
          //   }
          // }
        ],
      };
      const clientSlides = clients.map(({ node: c }) => (
        <div className={styles.clientSlide} key={`client-slide-${c.id}`}>
          {/* <img src={c.frontmatter.image.publicURL}/> */}
          <GatsbyImage
            image={c.frontmatter.image.childImageSharp.gatsbyImageData}
            alt="Client Logo"
            className={styles.image}
          />
        </div>
      ));

      return (
        <div className={styles.sliderContainer}>
          <Slider {...settings} className={styles.slider}>
            {clientSlides}
          </Slider>
        </div>
      );
    }
    return null;
  }
}

ClientRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query ClientRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { fields: { collection: { eq: "clients" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                collection
              }
              frontmatter {
                name
                link
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 630
                      placeholder: TRACED_SVG
                      layout: CONSTRAINED
                    )
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ClientRoll data={data} count={count} />}
  />
);
