import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import * as styles from './ScrollIndicator.module.scss';

const ScrollIndicator = class extends React.Component {
  container = React.createRef();

  updateScrollNumber(highlightIndex) {
    if (highlightIndex >= this.container.current.children.length) {
      return;
    }
    const indexChild = this.container.current.children.item(highlightIndex);
    for (let i = 0; i < this.container.current.children.length; i += 1) {
      if (this.container.current.children.item(i) !== indexChild) {
        this.container.current.children.item(i).className =
          styles.indicatorItem;
      }
    }
    indexChild.className = [styles.indicatorItem, styles.selected].join(' ');
  }

  render() {
    const indicatorItems = [];
    const { indicatorCount, indicatorClicked } = this.props;

    for (let i = 0; i < indicatorCount; i += 1) {
      const scrollToIndex = i;
      indicatorItems.push(
        <div
          key={`scroll-indicator-${i}`}
          className={styles.indicatorItem}
          onClick={() => {
            indicatorClicked(scrollToIndex);
          }}
        />
      );
    }

    return (
      <div ref={this.container} className={styles.indicatorContainer}>
        {indicatorItems}
      </div>
    );
  }
};

ScrollIndicator.propTypes = {
  indicatorCount: PropTypes.number.isRequired,
  indicatorClicked: PropTypes.func.isRequired,
};

export default ScrollIndicator;
