// extracted by mini-css-extract-plugin
export var description = "TestimonialRoll-module--description--yEWPT";
export var document = "TestimonialRoll-module--document--CV4fz";
export var line = "TestimonialRoll-module--line--FiPfJ";
export var quoteIcon = "TestimonialRoll-module--quote-icon--PpZJk";
export var service = "TestimonialRoll-module--service--uizEc";
export var serviceObject = "TestimonialRoll-module--service-object--X84Ig";
export var sliderContainer = "TestimonialRoll-module--slider-container--RS5+o";
export var testimonialAuthor = "TestimonialRoll-module--testimonial-author--u9kfi";
export var testimonialCard = "TestimonialRoll-module--testimonial-card--zJj96";
export var testimonialContent = "TestimonialRoll-module--testimonial-content--rAL7Z";
export var testimonialRight = "TestimonialRoll-module--testimonial-right--W8OVG";
export var testimonialSlide = "TestimonialRoll-module--testimonial-slide--kGELK";
export var text = "TestimonialRoll-module--text--60Emu";
export var title = "TestimonialRoll-module--title--728ka";
export var viewLink = "TestimonialRoll-module--view-link--eY+Ay";
export var viewLinkSeperator = "TestimonialRoll-module--view-link-seperator--xyDyt";