import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

import FrontBlogRoll from '../components/BlogRoll';
import FrontCaseStudyRoll from '../components/CaseStudyRoll';
import ClientRoll from '../components/ClientRoll';
import Layout from '../components/Layout';
import ScrollIndicator from '../components/ScrollIndicator';
import ServiceRoll from '../components/ServiceRoll';
import TestimonialRoll from '../components/TestimonialRoll';
import isoA from '../img/isometric/iso_a.svg';
import isoB from '../img/isometric/iso_b.svg';
import isoL from '../img/isometric/iso_l.svg';
import bigThree from '../img/logo_mark_blue.svg';
import modalIcon from '../img/service_development.png';
import { screenHeight, scrollWheelListener } from '../utils';

const smoothScroll = require('smoothscroll');

export class IndexPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.sideScrollContainer = React.createRef();

    this.welcomeBox = React.createRef();
    this.learnMoreScrollPosition = React.createRef();

    this.aboveFoldSection = React.createRef();
    this.servicesSection = React.createRef();
    this.ourWorkSection = React.createRef();
    this.testimonialSection = React.createRef();
    this.ourBlogSection = React.createRef();
    this.footerSection = React.createRef();

    this.state = { popdownHeight: null, modalIsOpen: false };

    // keep track of the current scrolled to section
    this.scrolledSection = 0;
    this.scrollPosition = 0;
    this.scrolling = false;

    this.scrollListener = this.scrollListener.bind(this);
    this.scrollWheelListener = scrollWheelListener.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.getPopdownMenuHeight = this.getPopdownMenuHeight.bind(this);
    this.getPopdownFooterPosition = this.getPopdownFooterPosition.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    document.documentElement.classList.toggle('noscroll', false);
    this.sideScrollContainer.current.updateScrollNumber(this.scrolledSection);

    window.addEventListener('scroll', this.scrollListener);
    window.addEventListener('wheel', this.scrollWheelListener);

    // TODO: Investigate why this nicer solutiion doesn't work
    /* window.addEventListener('hashchange', () => {
      console.log("HASHCHANGED");
      this.parseWindowHash();
    }); */

    // Handle hash URLs (e.g. #blog, #about-us)
    let storedHash = window.location.hash;
    window.setInterval(() => {
      if (window.location.hash !== storedHash) {
        storedHash = window.location.hash;
        this.parseWindowHash();
      }
    }, 100);
    this.parseWindowHash();

    // The Covid Packages modal
    // // get the API result via jQuery.ajax
    // fetch(
    //   `https://us-central1-lab3-website-1555301298730.cloudfunctions.net/getIpLocation`
    // )
    //   .then(res => res.json())
    //   .then(json => {
    //     Modal.setAppElement('#main');
    //     if (json.country_code === 'GB') {
    //       setTimeout(() => {
    //         this.openModal();
    //       }, 10000);
    //     }
    //   })
    //   .catch(e => console.log(e));

    Modal.setAppElement('#main');
    setTimeout(() => {
      this.openModal();
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
    window.removeEventListener('wheel', this.scrollWheelListener);
  }

  getPopdownMenuHeight() {
    if (!this.servicesSection.current) {
      return 170;
    }
    const windowHeight = screenHeight();
    return this.servicesSection.current.offsetTop - 5 + windowHeight;
  }

  getPopdownFooterPosition() {
    if (!this.footerSection.current) {
      return Infinity;
    }
    const windowHeight = screenHeight();
    return (
      this.footerSection.current.offsetTop +
      this.footerSection.current.offsetHeight +
      windowHeight
    );
  }

  openModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  scrollToSection(sectionNumber) {
    if (!this.allScrollableSections) {
      return;
    }
    // let scrollContainer = document.getElementById("index-scroll-view");
    const scrollContainer = document.body;
    const section = this.allScrollableSections()[sectionNumber];
    if (!section || !section.current || !scrollContainer) {
      return;
    }
    // smoothScroll(section.current, 600, () => {}, scrollContainer);
    smoothScroll(section.current, 600);
  }

  learnMoreClicked() {
    this.scrollToSection(1);
  }

  scrollToTop(event) {
    event.preventDefault();
    this.scrollToSection(0);
    window.history.replaceState(null, '', '/');
  }

  scrollListener() {
    const { popdownHeight } = this.state;

    if (!popdownHeight) {
      this.setState({
        popdownHeight: this.getPopdownMenuHeight(),
      });
    }
    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        if (!this.sideScrollContainer.current || !document.body) {
          return;
        }
        this.scrollPosition =
          document.body.scrollTop || document.documentElement.scrollTop;
        this.scrolling = false;
        const windowHeight = screenHeight();

        const currentScroll = this.calculateSectionFromScrollPosition(
          this.scrollPosition - windowHeight
        );
        if (currentScroll !== this.scrolledSection) {
          this.scrolledSection = currentScroll;
          this.sideScrollContainer.current.updateScrollNumber(currentScroll);
        }
      });
      this.scrolling = true;
    }
  }

  parseWindowHash() {
    switch (window.location.hash.slice(1)) {
      case 'services':
        this.scrollToSection(2);
        break;
      case 'our-work':
        this.scrollToSection(3);
        break;
      case 'about-us':
        this.scrollToSection(4);
        break;
      case 'blog':
        this.scrollToSection(5);
        break;
      case 'contact':
        this.scrollToSection(6);
        break;
      default:
        break;
    }
  }

  allScrollableSections() {
    return [
      this.aboveFoldSection,
      this.learnMoreScrollPosition,
      this.servicesSection,
      this.ourWorkSection,
      this.testimonialSection,
      this.ourBlogSection,
      this.footerSection,
    ];
  }

  calculateSectionFromScrollPosition(scrollPosition) {
    let newSection = 0;
    const sections = this.allScrollableSections();

    if (scrollPosition + 2 * window.innerHeight >= document.body.offsetHeight) {
      return 6;
    }

    while (newSection < sections.length - 1) {
      const nextSection = sections[newSection + 1];
      if (
        nextSection.current &&
        nextSection.current.offsetTop > scrollPosition + 5
      ) {
        return newSection;
      }
      newSection += 1;
    }
    return newSection;
  }

  render() {
    const { title, heading, subheading, welcome } = this.props;
    const { popdownHeight, modalIsOpen } = this.state;
    return (
      <Layout popdownHeight={popdownHeight}>
        <div>
          <div
            id="index-scroll-view"
            className="parallax-scroll-content"
            ref={this.aboveFoldSection}
          >
            <div className="above-the-fold">
              <ParallaxBanner
                className="background-three-parallax"
                layers={[
                  {
                    image: bigThree,
                    amount: 0.5,
                  },
                ]}
              />
              <div className="title-section">
                <h1>{title}</h1>
                <div className="sub-heading">
                  <h2 className="">{heading}</h2>
                  <h3 className="">{subheading}</h3>
                </div>
                <div className="learn-more-button-container">
                  <script
                    type="text/javascript"
                    src="https://widget.clutch.co/static/js/widget.js"
                  ></script>{' '}
                  <div
                    class="clutch-widget"
                    data-url="https://widget.clutch.co"
                    data-widget-type="2"
                    data-height="45"
                    data-darkbg="1"
                    data-clutchcompany-id="1407789"
                  ></div>
                  <button
                    type="button"
                    className="learn-more-button"
                    onClick={this.learnMoreClicked.bind(this)}
                  >
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>

            <div className="main-content-section">
              <div
                ref={this.learnMoreScrollPosition}
                className="lab3-container column is-12 learn-more-scroll-position"
              />

              <Parallax
                y={[5, -60]}
                className="parallax-will-change parallax-welcome"
              >
                <div ref={this.welcomeBox} className="welcome-section columns">
                  <h2 className="welcome-title column is-two-fifths">
                    {welcome.title}
                  </h2>
                  <div className="welcome-description column is-three-fifths">
                    {welcome.description}
                  </div>
                </div>
              </Parallax>

              <section className="section section--gradient">
                <div className="columns">
                  <div
                    className="column is-12"
                    style={{ paddingBottom: 0, width: '100%' }}
                  >
                    <div className="column is-12 lab3-container">
                      <div ref={this.servicesSection}>
                        <ServiceRoll />
                      </div>
                      <div ref={this.ourWorkSection}>
                        <SectionParallaxBackdrop
                          title="Our Work"
                          image={isoL}
                          rightAligned
                          higherImage={false}
                        />
                        <h2 className="section-title">Our Work</h2>
                        <FrontCaseStudyRoll />
                        <div className="has-text-centered">
                          <Link className="read-more-button" to="/case_studies">
                            <div>More Work</div>
                          </Link>
                        </div>
                      </div>
                      <div ref={this.testimonialSection}>
                        <SectionParallaxBackdrop
                          title="Testimonials"
                          image={isoA}
                          rightAligned={false}
                          higherImage={false}
                        />
                        <h2 className="section-title">Testimonials</h2>
                        <TestimonialRoll />
                      </div>

                      <div ref={this.ourBlogSection}>
                        <SectionParallaxBackdrop
                          title="Our Blog"
                          image={isoB}
                          rightAligned
                          higherImage
                        />
                        <h2 className="section-title">Our Blog</h2>
                        <FrontBlogRoll />
                        <div className="has-text-centered">
                          <Link className="read-more-button" to="/blog">
                            <div>More Blog Posts</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      ref={this.footerSection}
                      className="column is-12 clients-section"
                    >
                      <div className="lab3-container">
                        <h3 className="clients-title">
                          Clients we&apos;ve worked with
                        </h3>
                        <ClientRoll />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            className="uk-modal"
            overlayClassName="uk-modal-overlay"
            contentLabel="Support Packages"
            closeTimeoutMS={500}
          >
            <button
              type="button"
              className="modal-close-button"
              onClick={this.closeModal}
            />
            <div className="uk-modal-content">
              <img src={modalIcon} className="uk-modal-img" alt="" />
              <div className="uk-modal-text-content">
                <h2 className="modal-title">The End of an Era</h2>
                <div className="modal-contact-line" />
                <p className="modal-text">
                  In 2022 the Lab3 Apps team made the difficult decision to shut
                  down, and we'll each be moving on to other things. We had an
                  amazing 6+ years working together and helping deliver our
                  clients their software needs.{' '}
                </p>
                <br />
                <p className="modal-text">
                  Feel free to have a look around at a small selection of what
                  we worked on and wrote about during our time together, and
                  feel free to get in touch if needed.
                </p>
              </div>
            </div>
          </Modal>
          <ScrollIndicator
            ref={this.sideScrollContainer}
            indicatorCount={this.allScrollableSections().length}
            indicatorClicked={this.scrollToSection}
          />
        </div>
      </Layout>
    );
  }
}

const SectionParallaxBackdrop = ({
  title,
  image,
  rightAligned,
  higherImage,
}) => {
  return (
    <div className="lab3-container column is-12 backdrop-graphics-parallax">
      <Parallax y={[30, -15]}>
        <div className="background-text">{title}</div>
      </Parallax>
      <Parallax
        y={
          rightAligned
            ? ['100px', '-200px']
            : higherImage
            ? ['-500px', '-800px']
            : ['0px', '-300px']
        }
        className={[
          rightAligned ? 'right-image' : 'left-image',
          higherImage ? 'higher-image' : '',
        ].join(' ')}
      >
        <img src={image} alt="" />
      </Parallax>
    </div>
  );
};

SectionParallaxBackdrop.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  rightAligned: PropTypes.bool.isRequired,
  higherImage: PropTypes.bool.isRequired,
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  welcome: PropTypes.object.isRequired,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageTemplate
      title={frontmatter.title}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      welcome={frontmatter.welcome}
      about={frontmatter.about}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        welcome {
          title
          description
        }
        about
      }
    }
  }
`;
